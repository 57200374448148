/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
// @ts-ignore -- TS wants us to import `google-one-tap`, but this repo just uses `window.google` instead.
import type { CredentialResponse } from '@types/google-one-tap';
import jwtDecode from 'jwt-decode';

import config from '@nerdwallet/app-config';
import classes from '@nerdwallet/base-styles/classes';
import { getLogger } from '@nerdwallet/logging';
import { setTrk } from '@nerdwallet/nw-client-lib/trk-helper';

import type { ReduxStore } from '../redux/types';
import { shouldSkipVertical } from '../lib/helpers';
import { transformTaxonomy } from '../.././lib/helpers';

import {
  VERTICALS_TO_SKIP_ENTIRELY,
  VERTICALS_TO_SKIP_ON_DESKTOP,
  VERTICALS_TO_SKIP_ON_MOBILE,
  URLS_TO_SKIP_ENTIRELY,
  URLS_TO_SKIP_ON_DESKTOP,
  URLS_TO_SKIP_ON_MOBILE,
  CLIENT_IDS_TO_SKIP_ENTIRELY,
} from '../lib/constants';
import { trackImpression, trackInteraction } from '../lib/analytics.helper';
import ContextualIgnorableModal from './ContextualIgnorableModal';
import { RegisteredUserContext } from '../global/RegisteredUserContext';
import { G1T as analyticsBase } from '../lib/analytics.constants';

import useContextualIgnorableModal from '../hooks/useContextualIgnorableModal';

import styles from './OneTapComponent.module.less';
import useAnalytics from '../../lib/segment/useAnalytics';
import { REDIRECT_TO_HOME_TAXES_URLS } from '../lib/constants';
import { getCookie } from '../../../global-nav/components/utilities/cookies';
import { SEGMENT_EVENT } from '@nerdwallet/js-auth-tools/dist/web/new-steps/constants';

interface OneTapComponentProps {
  setIsAuth0Loading: (val: boolean) => void;
  showGoogleOneTap: boolean;
  showNerdwalletModals: boolean;
}
interface GoogleCredential {
  email: string;
}

const logger = getLogger('one-tap-component');

const addCss = (css: string) => {
  const head = document.getElementsByTagName('head')[0];
  const s = document.createElement('style');
  s.setAttribute('type', 'text/css');
  /* @ts-ignore internet explorer case */
  if (s.styleSheet) {
    // IE
    /* @ts-ignore internet explorer case */
    s.styleSheet.cssText = css;
  } else {
    // the world
    s.appendChild(document.createTextNode(css));
  }
  head.appendChild(s);
};

const OneTapComponent: React.FC<OneTapComponentProps> = memo(
  ({
    setIsAuth0Loading,
    showGoogleOneTap = true,
    showNerdwalletModals = true,
  }) => {
    const isLoggedIn = useSelector<ReduxStore, ReduxStore['isLoggedIn']>(
      (store) => store.isLoggedIn
    );
    const pageVertical = useSelector<ReduxStore, ReduxStore['pageVertical']>(
      (store) => store.pageVertical
    );
    const pageTaxonomy = useSelector<ReduxStore, ReduxStore['pageTaxonomy']>(
      (store) => store.pageTaxonomy
    );
    const clientID = useSelector<ReduxStore, ReduxStore['clientID']>(
      (store) => store.clientID
    );
    const originatingUrl = useSelector<
      ReduxStore,
      ReduxStore['originatingUrl']
    >((store) => store.originatingUrl);
    const segmentTrackingAttributes = useSelector<
      ReduxStore,
      ReduxStore['segmentTrackingAttributes']
    >((store) => store.segmentTrackingAttributes);

    const initialSegmentTrackingAttributes = useRef(segmentTrackingAttributes);

    const { identify, clearUncacheableTraits, getAnonymousId, track } =
      useAnalytics();

    const dispatch = useDispatch();

    const { isRegisteredUser } = useContext(RegisteredUserContext);

    const [shouldShowContextualIgnorable, _setShouldShowContextualIgnorable] =
      useState(false);

    const setShouldShowContextualIgnorable = useCallback(
      (val) => {
        if (!val) return _setShouldShowContextualIgnorable(val);
        if (!showNerdwalletModals) {
          logger.debug('Suppressing Contextual Ignorable modal');
          return;
        }
        _setShouldShowContextualIgnorable(val);
      },
      [showNerdwalletModals]
    );

    const hasRendered = useRef(false);

    const isPageExcludedOnDesktop =
      shouldSkipVertical({
        pageVertical,
        pageTaxonomy,
        verticalsToSkip: VERTICALS_TO_SKIP_ON_DESKTOP,
      }) || URLS_TO_SKIP_ON_DESKTOP.includes(originatingUrl);

    const isPageExcludedOnMobile =
      shouldSkipVertical({
        pageVertical,
        pageTaxonomy,
        verticalsToSkip: VERTICALS_TO_SKIP_ON_MOBILE,
      }) || URLS_TO_SKIP_ON_MOBILE.includes(originatingUrl);

    const isSuppressedOnDesktop = isPageExcludedOnDesktop;
    const isSuppressedOnMobile = isPageExcludedOnMobile;

    const {
      regFlow,
      analyticsBase: contextualIgnorableAnalyticsBase,
      experimentId,
      flow: contextualIgnorableFlow,
    } = useContextualIgnorableModal() ?? {};

    const redirectToTaxHome = REDIRECT_TO_HOME_TAXES_URLS.includes(
      window.location.pathname
    );

    const loadOneTap = useCallback(() => {
      const handleGoogleOneTapAuth0 = async (
        gArgs: CredentialResponse
      ): Promise<void> => {
        track(SEGMENT_EVENT.ELEMENT_INTERACTION, {
          entity_name: analyticsBase.entity_names.login,
          section_name: analyticsBase.section_name,
          registration_cohort: analyticsBase.cohort,
          element_type: analyticsBase.additional_attributes.element_type,
        });
        setIsAuth0Loading(true);
        const anonymousUserId = await getAnonymousId();

        const myNerdWalletRedirectPage = redirectToTaxHome
          ? 'taxes'
          : 'dashboard';

        const { email } = jwtDecode<GoogleCredential>(gArgs.credential);
        const trkParams = {
          anonymousUserId,
          driverPageName: segmentTrackingAttributes?.driverPageName,
          driverPageUrl: window?.location?.href,
          deployableName: segmentTrackingAttributes?.deployableName,
          deployableVersion: segmentTrackingAttributes?.deployableVersion,
          driverPageViewId: segmentTrackingAttributes?.driverPageViewId,
          driverContentId: segmentTrackingAttributes?.driverContentId,
          driverContentSource: segmentTrackingAttributes?.driverContentSource,
          driverFlowName: segmentTrackingAttributes?.driverFlowName,
          mostRecentTraditionalDeviceId: getCookie('cookie_id'),
          ...analyticsBase.segmentTrackingAttributes,
          login_hint: email,
          connection: 'google-oauth2',
          redirectTo: redirectToTaxHome
            ? `${window?.location?.protocol}//${window?.location?.host}/home/${myNerdWalletRedirectPage}`
            : window.location.href,
        };

        const link = setTrk({
          url: '/api/nts/v1/login',
          params: trkParams,
          taxonomy: transformTaxonomy(pageTaxonomy),
        });

        window.location.href = link;
      };

      if (!showGoogleOneTap) {
        logger.debug('skip render: showGoogleOneTap === false');
        logger.debug('setting showingContextualIgnorable to true');
        setShouldShowContextualIgnorable(true);
        return;
      }

      if (!window?.google?.accounts?.id) {
        logger.debug('skip render: !window?.google?.accounts?.id');
        logger.debug('setting showingContextualIgnorable to true');
        setShouldShowContextualIgnorable(true);
        return;
      }

      logger.debug('start render');

      hasRendered.current = true;

      window.google.accounts.id.initialize({
        callback: handleGoogleOneTapAuth0,
        client_id: `${config.GOOGLE_API_CLIENT_ID}`,
        itp_support: false,
        prompt_parent_id: 'google-one-tap-embed-target',
        cancel_on_tap_outside: false,
      });

      window.google.accounts.id.prompt((notification) => {
        const hasCredentialPickerIFrame = document.getElementById(
          'credential_picker_iframe'
        );

        if (notification.isDisplayed()) {
          if (isPageExcludedOnDesktop && !hasCredentialPickerIFrame) {
            logger.debug(
              `skip render on desktop: pageVertical === ${pageVertical} || originatingUrl === ${originatingUrl}`
            );
          } else if (isPageExcludedOnMobile && hasCredentialPickerIFrame) {
            logger.debug(
              `skip render on mobile: pageVertical === ${pageVertical} || originatingUrl === ${originatingUrl}`
            );
            addCss(`#credential_picker_iframe {display: none !important;}`);
          } else {
            trackImpression({
              entityName: analyticsBase.entity_names.display,
              sectionName: analyticsBase.section_name,
              registrationCohort: analyticsBase.cohort,
            });
            if (
              hasRendered.current &&
              initialSegmentTrackingAttributes.current ===
                segmentTrackingAttributes
            ) {
              track(SEGMENT_EVENT.ELEMENT_IMPRESSION, {
                entity_name: analyticsBase.entity_names.display,
                section_name: analyticsBase.section_name,
                registration_cohort: analyticsBase.cohort,
                element_type: analyticsBase.additional_attributes.element_type,
              });
            }
            logger.debug('prompt displayed successfully');
          }
        } else if (
          notification.isDisplayMoment() &&
          notification.isNotDisplayed()
        ) {
          logger.debug(
            'prompt not displayed ',
            notification.getNotDisplayedReason()
          );
          if (notification.getNotDisplayedReason() !== 'suppressed_by_user') {
            trackImpression({
              entityName: analyticsBase.entity_names.failed_render,
              sectionName: analyticsBase.section_name,
              registrationCohort: analyticsBase.cohort,
            });
            track(SEGMENT_EVENT.ELEMENT_IMPRESSION, {
              entity_name: analyticsBase.entity_names.failed_render,
              section_name: analyticsBase.section_name,
              registration_cohort: analyticsBase.cohort,
              element_type: analyticsBase.additional_attributes.element_type,
            });
            logger.debug(
              `skip G1T render: ${notification.getNotDisplayedReason()}`
            );
            setShouldShowContextualIgnorable(true);
          }
        } else if (
          notification.isSkippedMoment() &&
          notification.getSkippedReason() === 'user_cancel'
        ) {
          // Tracking users that intentionally clicked the 'X'
          trackInteraction({
            entityName: analyticsBase.entity_names.x_button,
            sectionName: analyticsBase.section_name,
            action: 'cancel',
            registrationCohort: analyticsBase.cohort,
          });
          track(SEGMENT_EVENT.ELEMENT_INTERACTION, {
            entity_name: analyticsBase.entity_names.x_button,
            section_name: analyticsBase.section_name,
            registration_cohort: analyticsBase.cohort,
            element_type: analyticsBase.additional_attributes.element_type,
          });
          logger.debug('prompt cancelled');
        }
      });
    }, [
      isPageExcludedOnDesktop,
      isPageExcludedOnMobile,
      originatingUrl,
      pageVertical,
      dispatch,
      segmentTrackingAttributes,
      identify,
    ]);

    useEffect(
      function loadOneTapIfNeeded() {
        if (
          hasRendered.current &&
          initialSegmentTrackingAttributes.current === segmentTrackingAttributes
        ) {
          // do nothing
        } else if (
          shouldSkipVertical({
            pageVertical,
            pageTaxonomy,
            verticalsToSkip: VERTICALS_TO_SKIP_ENTIRELY,
          })
        ) {
          logger.debug(
            `skip render entirely: pageVertical === ${pageVertical}`
          );
        } else if (URLS_TO_SKIP_ENTIRELY.includes(originatingUrl)) {
          logger.debug(
            `skip render entirely: originatingUrl === ${originatingUrl}`
          );
        } else if (CLIENT_IDS_TO_SKIP_ENTIRELY.includes(clientID)) {
          logger.debug(`skip render entirely: clientID === ${clientID}`);
        } else if (isLoggedIn) {
          logger.debug('skip render: User Logged In');
        } else {
          // reset shouldShowContextualIgnorable to false on re-render so modal is re-instantiated
          // this supports the setHeaderProps method of re-instantiating segmentTrackingAttributes
          if (
            initialSegmentTrackingAttributes.current !==
            segmentTrackingAttributes
          ) {
            setShouldShowContextualIgnorable(false);
          }
          loadOneTap();
        }
      },
      [
        clientID,
        isLoggedIn,
        isRegisteredUser,
        loadOneTap,
        originatingUrl,
        pageTaxonomy,
        pageVertical,
        clearUncacheableTraits,
        segmentTrackingAttributes,
      ]
    );

    // this check hides our modals if a user auths without refreshing or routing to another page
    if (isLoggedIn) return null;

    if (shouldShowContextualIgnorable) {
      return (
        <div
          className={classNames({
            [styles.skipMobileDisplay]: isSuppressedOnMobile,
            [styles.skipDesktopDisplay]: isSuppressedOnDesktop,
          })}
          id="contextual-reg-modal-container"
        >
          <ContextualIgnorableModal
            regFlow={regFlow}
            analyticsBase={contextualIgnorableAnalyticsBase}
            experimentId={experimentId}
            flow={contextualIgnorableFlow}
          />
        </div>
      );
    }

    return (
      <div className={styles.googleOneTapWrapper}>
        <div
          id="google-one-tap-embed-target"
          className={classes({
            // Because G1T renders into an iframe on mobile devices and not this div,
            // we can hide it entirely if we don't want to show G1T on desktop
            'display-none': isSuppressedOnDesktop,
          })}
        />
      </div>
    );
  }
);

OneTapComponent.displayName = 'OneTapComponent';

export default OneTapComponent;
