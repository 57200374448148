import localStorageHelper from '../lib/localStorage';

const SECONDS_ZERO = 0;
const SECONDS_IN_ONE_HOUR = 3600;
const SECONDS_IN_ONE_DAY = 86400;
const SECONDS_IN_ONE_WEEK = 604800;
const SECONDS_IN_FOUR_WEEKS = 2419200;

const BACKOFF_SERIES = [
  SECONDS_ZERO,
  SECONDS_IN_ONE_HOUR,
  SECONDS_IN_ONE_DAY,
  SECONDS_IN_ONE_WEEK,
  SECONDS_IN_FOUR_WEEKS,
];

export const MODAL_KEYS = {
  CONTEXTUAL: 'contextual',
  IGNORABLE: 'ignorable',
  LOGGED_OUT_TU: 'logged_out_tu',
  EORU_TU: 'eoru_tu',
};

const LOCAL_STORAGE_KEYS = {
  [MODAL_KEYS.CONTEXTUAL]: {
    COUNT: 'contextual_test_backoff_count',
    DATE: 'contextual_test_backoff_date',
  },
  [MODAL_KEYS.IGNORABLE]: {
    COUNT: 'ign_test_backoff_count',
    DATE: 'ign_test_backoff_date',
  },
  [MODAL_KEYS.LOGGED_OUT_TU]: {
    COUNT: 'logged_out_tu_backoff_count',
    DATE: 'logged_out_tu_backoff_date',
  },
  [MODAL_KEYS.EORU_TU]: {
    COUNT: 'eoru_tu_backoff_count',
    DATE: 'eoru_tu_backoff_date',
  },
};

export const incrementDisplayBackoff = (modalKey: string) => {
  const curVal = parseInt(
    localStorageHelper.getItem(LOCAL_STORAGE_KEYS[modalKey].COUNT) ?? '0',
    10
  );

  const next = curVal + 1;

  localStorageHelper.setItem(LOCAL_STORAGE_KEYS[modalKey].COUNT, `${next}`);
  localStorageHelper.setItem(
    LOCAL_STORAGE_KEYS[modalKey].DATE,
    `${Date.now()}`
  );
};

export const clearDisplayBackoff = (modalKey: string) => {
  localStorageHelper.setItem(LOCAL_STORAGE_KEYS[modalKey].COUNT, '0');
};

export const shouldSkipBecauseBackoff = (modalKey: string) => {
  const timeValue = localStorageHelper.getItem(
    LOCAL_STORAGE_KEYS[modalKey].DATE
  );
  if (!timeValue) return false;

  const curVal = parseInt(
    localStorageHelper.getItem(LOCAL_STORAGE_KEYS[modalKey].COUNT) ?? '0',
    10
  );
  const delay = BACKOFF_SERIES[Math.min(curVal, BACKOFF_SERIES.length - 1)];

  const timeDiff = Date.now() - parseInt(timeValue, 10);

  return timeDiff / 1000 < delay;
};

const __test = {
  LOCAL_STORAGE_KEYS,
  BACKOFF_SERIES,
};
export { __test };
