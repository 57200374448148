import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { getLogger } from '@nerdwallet/logging';
import { useTrackEvent } from '@nerdwallet/features';

import {
  REGISTRATION_MODALS,
  RegistrationModalType,
} from '@nerdwallet/js-auth-tools/registration';

import type { EventTypeElementInteracted } from '@nerdwallet/analytics';
import { ThirdPartyAuthFailReasonsFiltered } from '@nerdwallet/js-auth-tools/registration';
import type { IThirdPartyAuthFailReasons } from '@nerdwallet/js-auth-tools/registration';
import type { ReduxStore } from '../redux/types';
import { RegisteredUserContext } from '../global/RegisteredUserContext';
import { REDIRECT_TO_HOME_TAXES_URLS } from '../lib/constants';
import {
  getSmartAuthType,
  openAuthDialog,
  getRedirectUserBasedOnRedirectURI,
} from '../lib/helpers';
import type { SegmentTrackingAttributes } from '../lib/helpers';
import useAnalytics from '../../lib/segment/useAnalytics';

import {
  trackImpression,
  trackInteraction,
  trackPasswordRegisteredUserEvent,
} from '../lib/analytics.helper';

import {
  SHOW_REG_ERROR_STATUS,
  SHOW_REG_INBOX_SUCCESS_STATUS,
  SHOW_REG_SUCCESS_STATUS,
} from '../redux/constants';

import {
  MODAL_KEYS,
  clearDisplayBackoff,
  shouldSkipBecauseBackoff,
  incrementDisplayBackoff,
} from '../lib/incrementalBackoff';

import useIsMobile from '../hooks/useIsMobile';

import { IGNORABLE_V2_CREDIT_SCORE } from '../lib/analytics.constants';

import styles from './ContextualIgnorableModal.module.less';
import { SEGMENT_EVENT } from '@nerdwallet/js-auth-tools/dist/web/new-steps/constants';

type ContextualIgnorableModalProps = {
  regFlow?: RegistrationModalType;
  analyticsBase?: any;
  experimentId?: string;
  flow?: string;
};

const logger = getLogger('contextual-ignorable-reg-modal');

const NW_HOME_TAXES = '/home/taxes';

const ContextualIgnorableModal: React.FC<ContextualIgnorableModalProps> = ({
  regFlow = REGISTRATION_MODALS.CONTEXTUAL_IGNORABLE_MODAL,
  analyticsBase = IGNORABLE_V2_CREDIT_SCORE,
  experimentId,
  flow = 'no-security-question',
}) => {
  const segment = useAnalytics();
  const screenRef = useRef();

  const [userClosed, setUserClosed] = useState(false);
  const [showContextual, setShowContextual] = useState(true);
  const [showRequiresPassword, setShowRequiresPassword] = useState(false);

  const { isNavNarrow } = useIsMobile();

  const segmentTrackingAttributes = useSelector<
    ReduxStore,
    ReduxStore['segmentTrackingAttributes']
  >((store) => store.segmentTrackingAttributes);

  const { isRegisteredUser } = useContext(RegisteredUserContext);

  const dispatch = useDispatch();

  const redirectToTaxHome = REDIRECT_TO_HOME_TAXES_URLS.includes(
    window?.location.pathname
  );

  const dispatchShowStatus = useCallback(
    (type) => {
      dispatch({
        type,
      });
    },
    [dispatch]
  );

  const handleSuccess = ({
    entityName,
    sectionName,
    registrationCohort,
  }: {
    entityName: EventTypeElementInteracted['Payload']['entity_name'];
    sectionName: EventTypeElementInteracted['Payload']['section_name'];
    registrationCohort: EventTypeElementInteracted['Payload']['registration_cohort'];
  }) => {
    setShowContextual(false);
    trackInteraction({
      entityName,
      sectionName,
      action: 'close',
      registrationCohort,
    });
  };

  const [track] = useTrackEvent();

  useEffect(() => {
    if (shouldSkipBecauseBackoff(MODAL_KEYS.CONTEXTUAL)) {
      logger.debug('skip render: exponential backoff');
      return;
    }

    if (getSmartAuthType() !== 'register') {
      logger.debug('skip render: smart auth type is not "register"');
      return;
    }

    if (isRegisteredUser) {
      logger.debug('skip render: user is logged in');
      return;
    }

    const sendTrkLocation = () => {
      trackPasswordRegisteredUserEvent(analyticsBase.trk.trk_location, track);
    };

    const handleDispatch = (currentRegFlow) => {
      switch (currentRegFlow) {
        case REGISTRATION_MODALS.IGNORABLE_MODAL:
          dispatchShowStatus(SHOW_REG_SUCCESS_STATUS);
          break;
        case REGISTRATION_MODALS.BRANDED_INVESTING_CONTEXTUAL_IGNORABLE_MODAL:
          dispatchShowStatus(SHOW_REG_SUCCESS_STATUS);
          break;
        default:
          dispatchShowStatus(SHOW_REG_INBOX_SUCCESS_STATUS);
      }
    };

    const segmentTracking: SegmentTrackingAttributes = {
      driverPageName: segmentTrackingAttributes?.driverPageName,
      deployableName: segmentTrackingAttributes?.deployableName,
      deployableVersion: segmentTrackingAttributes?.deployableVersion,
      driverPageViewId: segmentTrackingAttributes?.driverPageViewId,
      driverContentId: segmentTrackingAttributes?.driverContentId,
      driverContentSource: segmentTrackingAttributes?.driverContentSource,
      driverFlowName: segmentTrackingAttributes?.driverFlowName,
      ...analyticsBase.segmentTrackingAttributes,
    };

    const handleRegisterAuthDialogHelper = () => {
      openAuthDialog({
        authType: 'register',
        trk: analyticsBase.trk,
        regTrackingAttributes: {
          cohort: analyticsBase.cohort,
        },
        segmentTrackingAttributes: {
          ...segmentTracking,
          driverElement: 'link',
        },
        onSuccess: (identity) => {
          handleSuccess({
            entityName: analyticsBase.entity_names.email_register,
            sectionName: analyticsBase.section_name,
            registrationCohort: analyticsBase.cohort,
          });
          if (experimentId && identity?.current?.authType === 'register') {
            trackPasswordRegisteredUserEvent(experimentId, track);
          }

          if (redirectToTaxHome) {
            window?.location?.assign(NW_HOME_TAXES);
          } else {
            const redirectCallback = getRedirectUserBasedOnRedirectURI();
            redirectCallback();
          }
          handleDispatch(regFlow);
        },
      });
    };

    const handleSignInAuthDialogHelper = () => {
      openAuthDialog({
        authType: 'signin',
        trk: analyticsBase.trk,
        regTrackingAttributes: {
          cohort: analyticsBase.cohort,
        },
        segmentTrackingAttributes: {
          ...segmentTracking,
          driverElement: 'link',
        },
        onSuccess: () => {
          handleSuccess({
            entityName: analyticsBase.entity_names.email_signin,
            sectionName: analyticsBase.section_name,
            registrationCohort: analyticsBase.cohort,
          });

          if (redirectToTaxHome) {
            window?.location?.assign(NW_HOME_TAXES);
          }
        },
      });
    };

    const handleClose = () => {
      trackInteraction({
        entityName: analyticsBase.entity_names.x_button,
        action: 'close',
        sectionName: analyticsBase.section_name,
        registrationCohort: analyticsBase.cohort,
      });
      segment.track(SEGMENT_EVENT.ELEMENT_INTERACTION, {
        entity_name: analyticsBase.entity_names.x_button,
        section_name: analyticsBase.section_name,
        registration_cohort: analyticsBase.cohort,
        element_type: analyticsBase.additional_attributes.element_type,
      });
      incrementDisplayBackoff(MODAL_KEYS.CONTEXTUAL);
      setUserClosed(true);
    };

    window.nwapi?.ui?.showAuthEmbed({
      el: screenRef.current,
      authType: 'register',
      flowConfig: {
        onClose: handleClose,
        openRegisterAuthDialog: handleRegisterAuthDialogHelper,
        openSignInAuthDialog: handleSignInAuthDialogHelper,
        sendTrkLocation,
        ...(redirectToTaxHome
          ? { redirectUrl: `${window.location.origin}${NW_HOME_TAXES}` }
          : {}),
      },
      version: 2,
      flow,
      trk: analyticsBase.trk,
      regTrackingAttributes: {
        entityNames: {
          authButtons: analyticsBase.entity_names.auth_buttons,
        },
        sectionName: analyticsBase.section_name,
        cohort: analyticsBase.cohort,
        additionalAttributes: analyticsBase.additional_attributes,
      },
      segmentTrackingAttributes: {
        ...segmentTracking,
        driverElement: 'button',
      },
      regFlow,
      onSuccess(identity) {
        trackImpression({
          entityName: analyticsBase.entity_names.success,
          sectionName: analyticsBase.section_name,
          registrationCohort: analyticsBase.cohort,
        });

        segment.track(SEGMENT_EVENT.ELEMENT_IMPRESSION, {
          entity_name: analyticsBase.entity_names.success,
          section_name: analyticsBase.section_name,
          registration_cohort: analyticsBase.cohort,
        });

        handleDispatch(regFlow);

        if (experimentId && identity?.current?.authType === 'register') {
          trackPasswordRegisteredUserEvent(experimentId, track);
        }

        clearDisplayBackoff(MODAL_KEYS.CONTEXTUAL);
      },
      onRenderFailure() {},
      onFailure(err: IThirdPartyAuthFailReasons) {
        if (ThirdPartyAuthFailReasonsFiltered.includes(err)) {
          dispatch({
            type: SHOW_REG_ERROR_STATUS,
            reason: err,
            analytics: analyticsBase.trk,
          });
        } else {
          setShowRequiresPassword(true);
        }

        trackImpression({
          entityName: analyticsBase.entity_names.error,
          sectionName: analyticsBase.section_name,
          registrationCohort: analyticsBase.cohort,
        });

        segment.track(SEGMENT_EVENT.ELEMENT_IMPRESSION, {
          entity_name: analyticsBase.entity_names.error,
          section_name: analyticsBase.section_name,
          registration_cohort: analyticsBase.cohort,
        });
      },
    });
  }, [
    dispatch,
    dispatchShowStatus,
    isRegisteredUser,
    analyticsBase,
    regFlow,
    track,
    experimentId,
    flow,
    segmentTrackingAttributes,
  ]);

  useEffect(() => {
    trackImpression({
      entityName: analyticsBase.entity_names.display,
      sectionName: analyticsBase.section_name,
      registrationCohort: analyticsBase.cohort,
    });

    segment.track(SEGMENT_EVENT.ELEMENT_IMPRESSION, {
      entity_name: analyticsBase.entity_names.display,
      section_name: analyticsBase.section_name,
      registration_cohort: analyticsBase.cohort,
      element_type: analyticsBase.additional_attributes.element_type,
    });
  }, [analyticsBase]);

  if (userClosed || !showContextual) {
    return null;
  }

  if (
    shouldSkipBecauseBackoff(MODAL_KEYS.CONTEXTUAL) ||
    getSmartAuthType() !== 'register' ||
    isRegisteredUser
  )
    return null;

  return (
    <div
      className={classnames(styles.modalWrapper, {
        [styles.modalWrapperLarge]: showRequiresPassword,
        [styles.narrowModalHeight]: isNavNarrow,
      })}
      id="contextual-reg-modal-container-wrapper"
    >
      <div id="contextual-reg-modal-desktop" ref={screenRef} />
    </div>
  );
};

export default ContextualIgnorableModal;
