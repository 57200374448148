import { getLogger } from '@nerdwallet/logging';

import _ from 'lodash';

import type {
  RegistrationEmbedType,
  RegistrationModalType,
} from '@nerdwallet/js-auth-tools/registration';

import { IncludeExcludeConfigProps } from './experiments';

import localStorageHelper from './localStorage';

const logger = getLogger('should-skip-vertical');

type PageTaxonomyAttribute = {
  nwVarName: string;
  bkVarName: string;
  value: string;
};

type PageTaxonomy = Array<PageTaxonomyAttribute>;

interface VerticalInfo {
  pageVertical: string;
  pageTaxonomy: PageTaxonomy;
  verticalsToSkip: Array<string>;
  clientID?: string;
}

export type SegmentTrackingAttributes = {
  driverPageName?: string;
  driverLocation?: string;
  driverSubLocation?: string;
  driverElement?: string;
  deployableName?: string;
  deployableVersion?: string;
  authProvider?: string;
  driverPageViewId?: string;
  driverContentId?: string;
  driverContentSource?: string;
  driverFlowName?: string;
  anonymousUserId?: string;
};

interface TrackingParams {
  // eslint-disable-next-line camelcase
  trk_location?: string;
  // eslint-disable-next-line camelcase
  trk_vertical?: string;
  // eslint-disable-next-line camelcase
  trk_format?: string;
}

interface OpenAuthDialogParams {
  authType?: 'signin' | 'register';
  featureFlags?: {
    skipChoosingInterest?: boolean;
  };
  flow?: string;
  onSuccess?: string | ((identity?: any) => void);
  regFlow?: RegistrationEmbedType | RegistrationModalType;
  regTrackingAttributes?: {
    entityNames?: Record<string, unknown>;
    cohort?: string;
  };
  segmentTrackingAttributes?: SegmentTrackingAttributes;
  trk?: TrackingParams;
  variantName?: string;
}

// This is a straight copypasta of https://github.com/NerdWallet/nw-auth-dialog/blob/a9aee1c9ba9d8e034cd1edf37e3e3c3e79f14f4a/src/components/auth-dialog.jsx#L17-L21
// For some inexplicable reason not worth debugging right now, importing a function from nw-auth-dialog causes `yarn dev` to fail.

export const getSmartAuthType = (): 'signin' | 'register' => {
  return localStorageHelper.getItem('hasSuccessfullyOnboarded')
    ? 'signin'
    : 'register';
};

export const getPageTaxonomyPropertyValue = (
  pageTaxonomy: PageTaxonomy,
  property: string
): string | undefined => {
  // added defensive checks pre-filter function due to pageTaxonomy format issues found in WEL-949
  if (pageTaxonomy && Array.isArray(pageTaxonomy)) {
    return pageTaxonomy.find(({ nwVarName }) => {
      return nwVarName === property;
    })?.value;
  }

  logger.error(
    `Error: pageTaxonomy is not an array. Taxonomy captured: ${JSON.stringify(
      pageTaxonomy
    )}`
  );

  // Return undefined to match return type of Array.find() above when
  // no match is found.
  return undefined;
};

// Determines the eligibility of a modal based on include/exclude configurations and page taxonomy.
// Returns true if the modal is eligible to be shown, and false otherwise.
export const isModalEligible = ({
  includeExcludeConfig,
  pageTaxonomy,
  originatingUrl,
}: {
  includeExcludeConfig: IncludeExcludeConfigProps;
  pageTaxonomy: VerticalInfo['pageTaxonomy'];
  originatingUrl: string;
}): boolean => {
  const {
    verticalsToInclude = [],
    contentTypesToInclude = [],
    topicsToInclude = [],
    urlsToInclude = [],
    urlMatch,
  } = includeExcludeConfig ?? {};
  const verticalName = getPageTaxonomyPropertyValue(pageTaxonomy, 'vertical');
  const contentType = getPageTaxonomyPropertyValue(pageTaxonomy, 'contentType');
  const topicName = getPageTaxonomyPropertyValue(pageTaxonomy, 'topic');

  // Check if the modal is eligible based on content type inclusion
  const isEligibleByContentType =
    contentTypesToInclude.length === 0 ||
    contentTypesToInclude.includes(contentType);

  // Check if the modal is eligible based on URLs to include
  const isEligibleByUrlsToInclude =
    urlsToInclude.length === 0 || urlsToInclude.includes(originatingUrl);

  // Check if the modal is eligible based on URL match
  const isEligibleByUrlMatch = !urlMatch || urlMatch.test(originatingUrl);

  // If includeExcludeConfig is empty or the modal is eligible based on content type, URLs, and URL match
  if (
    _.isEmpty(includeExcludeConfig) ||
    (isEligibleByContentType &&
      isEligibleByUrlsToInclude &&
      isEligibleByUrlMatch)
  ) {
    // If neither topicsToInclude nor verticalsToInclude are defined, mark the modal as eligible
    // Otherwise, show the modal if the article falls under a valid topic or a valid vertical
    return (
      (_.isEmpty(topicsToInclude) && _.isEmpty(verticalsToInclude)) ||
      topicsToInclude.includes(topicName) ||
      verticalsToInclude.includes(verticalName)
    );
  }

  return false;
};

export const shouldSkipVertical = ({
  pageVertical,
  pageTaxonomy,
  verticalsToSkip,
}: VerticalInfo): boolean => {
  // primary categories, i.e. Mortgages, will be reported as the primary page vertical
  if (verticalsToSkip.includes(pageVertical)) {
    return true;
  }

  // verticals like Credit Score, however, are secondary verticals and represented differently
  const topic = getPageTaxonomyPropertyValue(pageTaxonomy, 'topic');
  if (verticalsToSkip.includes(topic)) {
    return true;
  }

  return false;
};

export const openAuthDialog = (params: OpenAuthDialogParams): void => {
  const {
    authType,
    featureFlags,
    flow,
    onSuccess,
    regFlow,
    regTrackingAttributes,
    segmentTrackingAttributes,
    trk,
  } = params;

  window.nwapi.ui.showAuthDialog({
    authType,
    featureFlags,
    flow,
    flowConfig: {
      errorToastPositionMobile: 'bottom-center',
    },
    trk,
    onSuccess,
    regFlow,
    regTrackingAttributes,
    segmentTrackingAttributes,
    version: 2,
  });
};

interface getRedirectUserBasedOnRedirectURIProps {
  otherwiseGoTo?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getRedirectUserBasedOnRedirectURI = (
  props?: getRedirectUserBasedOnRedirectURIProps
) => {
  const params = new URLSearchParams(window.location.search);
  const redirectURI = params.get('redirect_uri');

  const newPath = redirectURI
    ? decodeURIComponent(redirectURI)
    : props?.otherwiseGoTo;

  if (!newPath) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }

  return () => window?.location?.assign(newPath);
};
