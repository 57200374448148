import {
  REGISTRATION_MODALS,
  RegistrationModalType,
} from '@nerdwallet/js-auth-tools/registration';

import { useSelector } from 'react-redux';
import type { ReduxStore } from '../redux/types';
import { isModalEligible } from '../lib/helpers';
import {
  CONTEXTUAL_IGNORABLE_MODAL_EXPERIMENTS,
  ExperimentConfig,
} from '../lib/experiments';
import {
  IGNORABLE_V1,
  IGNORABLE_V2_CREDIT_SCORE,
} from '../lib/analytics.constants';
import {
  CONTEXTUAL_VERTICALS_TO_INCLUDE,
  CONTEXTUAL_CONTENT_TYPES_TO_INCLUDE,
} from '../lib/constants';

export const defaultContextualIgnorableModalProps = {
  regFlow: REGISTRATION_MODALS.IGNORABLE_MODAL as RegistrationModalType,
  analyticsBase: IGNORABLE_V1,
};

const fallbackContextualModalConfig: ExperimentConfig = {
  id: 'fallback-contextual-modal',
  variants: {
    CONTROL: {
      id: 'control',
      regFlow:
        REGISTRATION_MODALS.CONTEXTUAL_IGNORABLE_MODAL as RegistrationModalType,
      analyticsBase: IGNORABLE_V2_CREDIT_SCORE,
    },
  },
  includeExcludeConfig: {
    verticalsToInclude: CONTEXTUAL_VERTICALS_TO_INCLUDE,
    contentTypesToInclude: CONTEXTUAL_CONTENT_TYPES_TO_INCLUDE,
  },
};

const useContextualIgnorableModal = (
  modalConfigs = CONTEXTUAL_IGNORABLE_MODAL_EXPERIMENTS
): {
  regFlow?: RegistrationModalType;
  analyticsBase?: any;
  experimentId?: string;
  flow?: string;
} => {
  const storedExperiments = useSelector<ReduxStore, ReduxStore['experiments']>(
    (store) => store.experiments
  );

  const pageTaxonomy = useSelector<ReduxStore, ReduxStore['pageTaxonomy']>(
    (store) => store.pageTaxonomy
  );

  const originatingUrl = useSelector<ReduxStore, ReduxStore['originatingUrl']>(
    (store) => store.originatingUrl
  );

  /**
   * The eligibleExperiments array is comprised of all experiments that are
   * currently eligible to be shown based on the current state of the user
   * and the includeExcludeConfig for each modal.
   */
  const eligibleExperiments = [fallbackContextualModalConfig, ...modalConfigs]
    .reverse()
    .filter(({ includeExcludeConfig }) =>
      isModalEligible({
        includeExcludeConfig,
        pageTaxonomy,
        originatingUrl,
      })
    );

  if (!eligibleExperiments.length) return defaultContextualIgnorableModalProps;

  /**
   * If there are multiple experiments eligible then we want to use the first
   * experiment in the eligibleExperiments array that's variant is currently active.
   */
  const firstExperimentWithVariantActive = eligibleExperiments.find(
    ({ id }) => {
      const { assignedVariantName } =
        storedExperiments?.find((test) => test.experimentName === id) ?? {};
      return assignedVariantName !== '' && assignedVariantName !== 'control';
    }
  );

  if (firstExperimentWithVariantActive) {
    const { assignedVariantName } =
      storedExperiments?.find(
        (test) => test.experimentName === firstExperimentWithVariantActive.id
      ) ?? {};
    const assignedVariant = Object.entries(
      firstExperimentWithVariantActive?.variants
    ).find((variant) => variant[1]?.id === assignedVariantName);

    if (assignedVariant) {
      return {
        experimentId: firstExperimentWithVariantActive.id,
        ...assignedVariant[1],
      };
    }
  }
  /**
   * If the variant is not active for any of the eligible experiments in
   * the eligibleExperiments array OR the assignedVariantName is not defined for the experiment
   * then the "control" variant of the first eligible experiment will be used.
   */
  return {
    experimentId: eligibleExperiments[0].id,
    ...eligibleExperiments[0].variants.CONTROL,
  };
};

export default useContextualIgnorableModal;
